'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var common_1 = require("./class/common");
var navi_1 = require("./class/navi");
var top_1 = require("./class/top");
var scroll_1 = require("./class/scroll");
var contact_1 = require("./class/contact");
//import Init     from './class/init';
$(function () {
    new common_1.default.MyAnchorLink();
    new navi_1.default();
    new top_1.default();
    new scroll_1.default();
    new contact_1.default();
    //new Init();
});
