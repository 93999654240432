'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scroll = void 0;
var config_1 = require("../config");
var $ = require("jquery");
var Scroll = /** @class */ (function () {
    function Scroll() {
        var _this = this;
        this.VIEW_SCROLL_PARAM = config_1.default.VIEW_SCROLL_PARAM;
        $(window).on('load scroll', function () {
            var isMenuOpen = $('.trigSpNavi').attr('data-is-show');
            if (isMenuOpen === 'false') {
                var scroll_1 = $(window).scrollTop();
                if (scroll_1 < _this.VIEW_SCROLL_PARAM) {
                    $('.bulletHeaderMenu').attr('data-is-fixed', 'false');
                    $('.bulletSpNavi').attr('data-is-fixed', 'false');
                    $('.bulletHeaderLogo').attr('data-is-fixed', 'false');
                }
                else {
                    $('.bulletHeaderMenu').attr('data-is-fixed', 'true');
                    $('.bulletSpNavi').attr('data-is-fixed', 'true');
                    $('.bulletHeaderLogo').attr('data-is-fixed', 'true');
                }
            }
        });
    }
    return Scroll;
}());
exports.Scroll = Scroll;
exports.default = Scroll;
