'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navi = void 0;
var config_1 = require("../config");
var $ = require("jquery");
var Navi = /** @class */ (function () {
    function Navi() {
        var _this = this;
        this.VIEW_SCROLL_PARAM = config_1.default.VIEW_SCROLL_PARAM;
        $('.trigSpNavi').on('click', function (e) {
            var isShow = $(e.currentTarget).attr('data-is-show');
            if (isShow === 'false') {
                $('html, body').css('overflow', 'hidden');
                $('.bulletHeaderLogo').attr('data-is-fixed', 'false');
                $('.bulletSpNavi').attr('data-is-show', 'true');
                $('.bulletMenu').fadeIn('slow');
            }
            else {
                $('html, body').css('overflow', 'auto');
                $('.bulletSpNavi').attr('data-is-show', 'false');
                $('.bulletMenu').fadeOut('slow');
                var scroll_1 = $(window).scrollTop();
                if (scroll_1 >= _this.VIEW_SCROLL_PARAM) {
                    $('.bulletHeaderLogo').attr('data-is-fixed', 'true');
                }
            }
        });
    }
    return Navi;
}());
exports.Navi = Navi;
exports.default = Navi;
