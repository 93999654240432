'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var config_1 = require("../config");
var $ = require("jquery");
var MyCommon;
(function (MyCommon) {
    var MyAnchorLink = /** @class */ (function () {
        function MyAnchorLink() {
            var _this = this;
            this.headerHeight = 0;
            this.headerPcHeight = 90;
            this.headerSpHeight = 60;
            this.scrollSpeed = 550;
            this.scrollEasing = "swing";
            this.OVER_PC_WIDTH = config_1.default.OVER_PC_WIDTH;
            var self = this;
            $('a').click(function () {
                var href = $(this).attr("href");
                if (self.isAnchor(href)) {
                    self.scrollToAnchor(href);
                    return false;
                }
            });
            // URLがアンカーリンク付きだった時
            window.addEventListener('load', function () {
                var url = location.href;
                if (_this.isAnchor(url)) {
                    $("html, body").animate({ scrollTop: 0 }, 0, "linear");
                    self.scrollToAnchor(url);
                    return false;
                }
            });
        }
        MyAnchorLink.prototype.isAnchor = function (href) {
            return href.indexOf('#') != -1;
        };
        MyAnchorLink.prototype.getAnchorName = function (href) {
            var index = href.indexOf('#');
            return href.slice(index + 1);
        };
        MyAnchorLink.prototype.scrollToAnchor = function (href) {
            this.changeHeaderHeight();
            var anchor = this.getAnchorName(href);
            var position = $('#' + anchor).offset().top - this.headerHeight;
            $("html, body").animate({ scrollTop: position }, this.scrollSpeed, this.scrollEasing);
        };
        // PCとスマホでheaderのパラメータを変える
        MyAnchorLink.prototype.changeHeaderHeight = function () {
            var browserWidth = $(window).outerWidth();
            if (browserWidth < this.OVER_PC_WIDTH) {
                this.headerHeight = this.headerSpHeight;
            }
            else {
                this.headerHeight = this.headerPcHeight;
            }
        };
        return MyAnchorLink;
    }());
    MyCommon.MyAnchorLink = MyAnchorLink;
})(MyCommon || (MyCommon = {}));
exports.default = MyCommon;
