'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Top = void 0;
var $ = require("jquery");
require("slick-carousel");
var Top = /** @class */ (function () {
    function Top() {
        var _this = this;
        var slider = '#bulletTopSlick';
        $(slider).on('init', function (slick) {
            $('.slick-slide').eq(0).addClass('slick-animation');
        });
        $(slider).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            if (nextSlide > 0) {
                $(slider).slick('slickSetOption', 'autoplaySpeed', 4000, true);
            }
            $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
            $(".slick-slide", this).eq(nextSlide).addClass("slick-animation");
        });
        $(slider).on('afterChange', function () {
            $(".preve-slide", this).removeClass("preve-slide slick-animation");
        });
        $(slider).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            speed: 1000,
            autoplaySpeed: 5000,
            autoplay: true,
            waitForAnimate: true,
            pauseOnFocus: false,
            pauseOnHover: false
        });
        $(window).on('load resize', function () {
            // 高さの調整
            _this.changeHeightSlide();
        });
    }
    Top.prototype.changeHeightSlide = function () {
        var height = $(window).outerHeight();
        $('.bulletMainVisual').css('height', height);
    };
    return Top;
}());
exports.Top = Top;
exports.default = Top;
