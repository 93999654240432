'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.emailFormatCheck = exports.telFormatCheck = exports.removeSpace = exports.checkNull = void 0;
// 空白チェック
function checkNull(val) {
    return this.removeSpace(val) != '';
}
exports.checkNull = checkNull;
// 空白文字の削除
function removeSpace(val) {
    if (val) {
        return val.replace(/\s+/g, '');
    }
    else {
        return '';
    }
}
exports.removeSpace = removeSpace;
// 電話番号のフォーマットチェック
function telFormatCheck(val) {
    return val.match(/[0-9]{2,4}[\-]{0,1}[0-9]{2,4}[\-]{0,1}[0-9]{2,4}/);
}
exports.telFormatCheck = telFormatCheck;
// メールのフォーマットチェック
function emailFormatCheck(val) {
    return val.match(/^[0-9a-zA-Z]+[0-9a-zA-Z\-\+_\.]+@[0-9a-zA-Z\-\+_\.]+\.[0-9a-zA-Z\-\+_\.]+$/g);
}
exports.emailFormatCheck = emailFormatCheck;
