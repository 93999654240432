'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Online = void 0;
var config_1 = require("../config");
var Func = require("../func");
var $ = require("jquery");
var Online = /** @class */ (function () {
    function Online() {
        this.headerHeight = 0;
        this.headerPcHeight = 90;
        this.headerSpHeight = 60;
        this.OVER_PC_WIDTH = config_1.default.OVER_PC_WIDTH;
        var self = this;
        $('#trigContactForm').on('click', function () {
            $('.bulletError').hide();
            self.getValues();
            if (self.validates()) {
                self.moveFormTop();
                return false;
            }
        });
    }
    Online.prototype.getValues = function () {
        this.type = $('[name=type]:checked').val();
        this.name = $('#name').val();
        this.nameKana = $('#nameKana').val();
        this.email = $('#email').val();
        this.emailRe = $('#email_re').val();
        this.inquiry = $('#inquiry').val();
        this.accept = $('[name=accept]:checked').val();
        console.log(this.name);
        console.log(this.nameKana);
    };
    Online.prototype.validates = function () {
        var errorFlag = false;
        this.errorName = "";
        if (!Func.checkNull(this.type)) {
            errorFlag = true;
            $('.bulletErrorType').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorType';
            }
        }
        if (!Func.checkNull(this.name)) {
            errorFlag = true;
            $('.bulletErrorName').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorName';
            }
        }
        if (!Func.checkNull(this.nameKana)) {
            errorFlag = true;
            $('.bulletErrorNameKana').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorNameKana';
            }
        }
        if (!Func.checkNull(this.email)) {
            errorFlag = true;
            $('.bulletErrorEmail').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorEmail';
            }
        }
        else if (!Func.emailFormatCheck(this.email)) {
            errorFlag = true;
            $('.bulletErrorEmailFormat').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorEmail';
            }
        }
        if (!Func.checkNull(this.emailRe)) {
            errorFlag = true;
            $('.bulletErrorEmailRe').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorEmail';
            }
        }
        else if (this.email != this.emailRe) {
            errorFlag = true;
            $('.bulletErrorEmailRe').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorEmail';
            }
        }
        if (!Func.checkNull(this.inquiry)) {
            errorFlag = true;
            $('.bulletErrorInquiry').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorInquiry';
            }
        }
        if (!Func.checkNull(this.accept)) {
            errorFlag = true;
            $('.bulletErrorAccept').show();
            if (this.errorName == "") {
                this.errorName = 'paramErrorAccept';
            }
        }
        return errorFlag;
    };
    Online.prototype.moveFormTop = function () {
        var browserWidth = $(window).outerWidth();
        if (browserWidth < this.OVER_PC_WIDTH) {
            this.headerHeight = this.headerSpHeight;
        }
        else {
            this.headerHeight = this.headerPcHeight;
        }
        var formPosition = $('.' + this.errorName).offset().top;
        $('html, body').animate({
            scrollTop: formPosition - this.headerHeight
        }, 'slow');
    };
    return Online;
}());
exports.Online = Online;
exports.default = Online;
